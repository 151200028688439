import {inject, Injectable} from "@angular/core";
import {Observable,} from "rxjs";
import {environment} from "src/environments/environment";
import {tap} from "rxjs/operators";
import {User} from "src/app/shared/model/user";
import {UpdateUser} from "src/app/shared/model/update-user";
import {Register} from "src/app/shared/model/register";
import {ApiService} from "src/app/shared/service/http.service";
import {AuthService} from "src/app/core/auth/authentication.service";
import {Friendship} from "../../shared/model/friendship";
import {Store} from "@ngrx/store";
import {UserActions} from "../../state/user.actions";
import {Media} from "../../shared/model/posts/postMedia";
import {Animal} from "../../shared/model/animal";

@Injectable({
  providedIn: "root",
})
export class UserService {
  authService = inject(AuthService)
  httpService = inject(ApiService);
  store = inject(Store)

  private userRoutes = environment.api.user;

  getFriends(id?: number) {
    return this.httpService.Get<Friendship[]>(this.userRoutes.friends, {params: {id}});
  }

  getAnimals(id?: number) {
    return this.httpService.Get<Animal[]>(this.userRoutes.animals, {params: {id}});
  }

  getMedia() {
    return this.httpService.Get<Media>(this.userRoutes.getMedia)
  }

  getInfo(id?: number): Observable<User> {
    const idIsSet = id !== undefined
    const params = idIsSet ? {params: {id}} : {};
    return this.httpService.Get(this.userRoutes.getUser, params)
  }

  /**
   * A call to update the customer profile with new data. THis method returns an observable, however, the
   * result is also published in the Observable userSource. So if the client already subscribes the new User
   * data by subscribing to userSource, that client will be notified once this operation has succeeded.
   */
  updateCustomerProfile(updateUser: UpdateUser): Observable<User> {
    return this.httpService
      .Post<User>(this.userRoutes.update, updateUser)
      .pipe(
        tap((res) => {
          if (res) {
            this.store.dispatch(UserActions.setUser({newUser: res}))
          }
        })
      );
  }


  getUserData(socialSecurityNr: string): Observable<Register> {
    return this.httpService.Get<User>(this.userRoutes.getData, {params: {socialSecurityNr: socialSecurityNr}});
  }

  public addUser(registerData: Register): Observable<Register> {
    return this.httpService.Post<Register>(this.userRoutes.register, registerData);
  }
}
