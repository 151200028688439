import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {User} from "../shared/model/user";

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Get Logged In User': emptyProps(),
    'Set User': props<{ newUser: User }>(),
    'Unset User': emptyProps(),
  }
})

export const UserApiActions = createActionGroup({
  source: 'User API',
  events: {
    'Load User': props<{ userId: number }>,
  }
})
