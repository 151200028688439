import {inject, Injectable} from "@angular/core";
import {environment} from "src/environments/environment";
import {ApiService} from "src/app/shared/service/http.service";
import {Router} from "@angular/router";
import {DialogService} from "src/app/shared/service/dialog.service";
import {Login} from "../../shared/model/login";
import {AuthToken} from "../../shared/model/auth-token";
import {catchError, map} from "rxjs/operators";
import {of, take} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  httpService = inject(ApiService);
  private authRoutes = environment.api.authenticate;
  private userRoutes = environment.api.user;

  constructor(
    private router: Router,
    public dialogService: DialogService,
  ) {
  }

  public AuthenticateUser() {
    return this.httpService.Get<boolean>(this.userRoutes.authenticate);
  }

  public loginUser(loginData: Login) {
    return this.httpService.Post<AuthToken>(this.authRoutes.getAuthentication, loginData);
  }

  sendResetPasswordRequest(email: string) {
    return this.httpService.Post(this.authRoutes.resetPasswordRequest, {email: email});
  }

  sendResetPassword(email: string, password: string, token: string) {
    return this.httpService.Post(this.authRoutes.resetPassword, {Email: email, Password: password, Token: token});
  }

  logout() {
    localStorage.clear();
    return this.httpService.Get<boolean>(this.authRoutes.logOut)
      .pipe(
        take(1),
        map(() => true),
        catchError(() => of(false)))
  }
}
